import React from 'react';

import { Redirect } from 'react-router-dom';

import { useConfig, useServerData, Status } from '@xingternal/hops';

import CustomFrame from './../../Layouts/CustomFrame';
import IllustrationGrid from './../../Layouts/IllustrationGrid';

import HtmlHead from './../../Components/HtmlHead/HtmlHead';
import GdprImage from './../../Components/Illustrations/GdprImage';
import PageviewTracker from './../../Components/PageviewTracker/PageviewTracker';
import UspSection from './../../Components/UspSection/UspSection';

import RegistrationForm from './../../Forms/RegistrationForm';

import useCookieConsent from './../../contexts/useCookieConsent';

import messages from './messages';

import illustration from './illustration.svg';
import eyeCatcherEng from './eyecatcher_en.svg';
import eyeCatcherGer from './eyecatcher_de.svg';

/* REG-7550 to be deleted */
const EYECATCHER_ENTITLED_CODES = [
  'sea548e7139',
  'sea18ba7033',
  'sea0f4741eb',
  'sea634de103',
  'sea9deb2efa',
  'seaa0d5fa68',
  'seadcb26656',
  'seabf258472',
  'seafb3b3e1e',
  'sea56b7f2ae',
  'sea016f3945',
  'seaad7d49f6',
  'sea7586c776',
  'sea08eb0c79',
  'sea6f3cbfb9',
];

const paramToFieldMap = { first_name: 'firstName', last_name: 'lastName' };

const Signup = ({ location }) => {
  const { externalLoginUrl } = useConfig();
  const { recurringUser, language, meAllowedArea } = useServerData();
  useCookieConsent();

  const queryParams = new URLSearchParams(location.search);
  const signupChannel = queryParams.get('signup_channel') || 'minireg_fullpage';
  const forceRegister = queryParams.get('registration') === '1';
  const forceLogin = queryParams.get('login') === '1';
  const seaCampaign = queryParams.get('sc_cmp');

  // remove these 2 values, if things should REALLY be visible to the end user
  const showRegisterViaCvSection = queryParams.get('cv_upload') === '1';
  const enableEmailOnly = queryParams.get('email_only') === '1';

  const hashParams = new URLSearchParams(location.hash.replace(/^#/, ''));

  const loginUrlData = new URL(externalLoginUrl);
  queryParams.delete('login');
  loginUrlData.search = queryParams.toString();
  const loginUrl = loginUrlData.toString();

  const illustrationGridProps = {
    illustration,
  };

  let formHeadline = messages.headline;

  if (EYECATCHER_ENTITLED_CODES.includes(seaCampaign)) {
    illustrationGridProps.eyeCatcher =
      language === 'de' ? eyeCatcherGer : eyeCatcherEng;
    formHeadline = messages.cmpHeadline;
  }

  const prefilledValues = Object.keys(paramToFieldMap).reduce(
    (accumulator, key) => {
      const paramValue = hashParams.get(key);
      if (paramValue) accumulator[paramToFieldMap[key]] = paramValue;
      return accumulator;
    },
    {}
  );

  if (typeof window !== 'undefined' && prefilledValues !== {}) {
    window.history.replaceState(
      null,
      document.title,
      location.pathname + location.search
    );
  }

  if (meAllowedArea === 'confirmation_page') {
    return (
      <>
        <Status code={302} />
        <Redirect to={`/start/confirmation`} />
      </>
    );
  }

  if (forceLogin || (!forceRegister && recurringUser)) {
    if (typeof window !== 'undefined') {
      window.location.href = loginUrl;
      return null;
    }

    return (
      <>
        <Status code={302} />
        <Redirect to={loginUrl} />
      </>
    );
  }

  return (
    <React.Fragment>
      <HtmlHead pageName={'signupPage'} />
      <PageviewTracker pageName={'wbm/Welcome/signup'} />
      <CustomFrame loginLink={loginUrl}>
        <IllustrationGrid {...illustrationGridProps}>
          <RegistrationForm
            emailOnly={enableEmailOnly}
            headlineMessage={formHeadline}
            prefilledValues={prefilledValues}
            signupChannel={signupChannel}
            cvUploadConfig={showRegisterViaCvSection ?
              {
                // using entity pages urn for testing purposes
                source: 'surn:x-xing:entitypages:page:244471.44c800',
                flowIdentifier: 'NormalRegistrationFlow',
              } : undefined
            }
            enableGoogleSignup
            enableAppleSignup
          />
          <GdprImage />
        </IllustrationGrid>
        <UspSection />
      </CustomFrame>
    </React.Fragment>
  );
};

export default Signup;
