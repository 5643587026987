import { useContext, useEffect } from 'react';

import CookieConsentContext from './CookieConsentContext';

const useCookieConsent = (enabled = true) => {
  const [cookieConsent, setCookieConsent] = useContext(CookieConsentContext);

  useEffect(() => {
    setCookieConsent({ disableBanner: !enabled });
  }, []);

  return cookieConsent;
};

export default useCookieConsent;
