import { defineMessages } from 'react-intl';

export default defineMessages({
  loginButton: {
    id: 'WELCOME_TOGGLE_FORM_BUTTON_LOGIN',
    defaultMessage: 'Einloggen',
  },
  headline: {
    id: 'WELCOME_REGISTRATION_HEADLINE_REG_7088_D',
    defaultMessage: 'WELCOME_REGISTRATION_HEADLINE_REG_7088_D',
  },
  cmpHeadline: {
    id: 'WELCOME_REGISTRATION_HEADLINE_REG_7550_CMP',
    defaultMessage: 'WELCOME_REGISTRATION_HEADLINE_REG_7550_CMP',
  },
});
